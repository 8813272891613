<template>
    <div class="table-page">
        <el-page-header class="page-header" @back="$router.go(-1)" content="微信设置"/>
        <div class="page-main">
            <el-row>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <el-form :inline="true">
                        <el-form-item label="角色">
                            <el-select v-model="form.role" placeholder="请选择" @change="getMenu">
                                <el-option v-for="item in roles" :key="item.value" :label="item.name"
                                           :value="item.value"/>
                            </el-select>
                            <el-form-item label=" ">
                                <el-button type="primary" @click="submit">保存</el-button>
                            </el-form-item>
                        </el-form-item>
                    </el-form>
                    <el-tree
                        :data="menuItems"
                        ref="tree"
                        show-checkbox
                        node-key="id"
                        default-expand-all
                        draggable
                        :expand-on-click-node="false">
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>
                        <i :class="`//${data.icon}`"></i>
                        {{ node.label }}
                    </span>
                    <span>
                    </span>
                  </span>
                    </el-tree>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <p>当前设置</p>
                    <el-tree :data="new_menu" default-expand-all></el-tree>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    import menu from "@/data/menu"
    import roles from "@/data/roles"

    export default {
        data() {
            return {
                menuItems: menu.menuItems,
                roles: roles.roles,
                form: {},
                new_menu: []
            }
        },
        methods: {
            async getMenu() {
                this.new_menu = []
                this.$refs.tree.setCheckedKeys([]);
                const res = await this.$http.get(`/auth/menu?role=${this.form.role}`)
                console.log(res.data.data)
                if (res.data.data) {
                    // this.new_menu = JSON.parse(res.data.data.menu)
                    // let checked = menu.filterChecked(this.new_menu, 'id')
                    // this.$refs.tree.setCheckedKeys(checked);
                    this.new_menu = res.data.data
                    this.$refs.tree.setCheckedNodes(this.new_menu)
                }
            },
            async submit() {
                if (!this.form.role) {
                    this.$message.error("请选择角色")
                    return
                }
                let res = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())
                let new_menu = menu.filterArrForKey(this.menuItems, 'id', res)
                // console.log(JSON.stringify(new_menu, null, 2))
                this.form['menu'] = JSON.stringify(new_menu, null, 2)
                const resp = await this.$http.post('/auth/menu', this.form)
                console.log(resp)
                // console.log(this.menuItems)
                this.getMenu()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
</style>
